/* .aiko-spu-selection .aiko-spu-selection-param {
  display: flex;
  justify-content: space-between;
} */

.aiko-spu-selection .aiko-spu-selection-param .assp-left {
  width: 100%;
}

/* .aiko-spu-selection .aiko-spu-selection-param .assp-left .assp-category .category-main  {
  min-height: 500px;
  overflow-y: scroll;
} */

.aiko-spu-selection .aiko-spu-selection-param .assp-right {
  width: 100%;
  padding: 12px;
  background: #fff;
  margin: 24px 0;
}

.aiko-spu-selection .aiko-spu-selection-param .assp-right .asspr-chart {
  width: 80vh;
  height: 0;
  padding-bottom: 80vh;
  position: relative;
  margin: 18px auto 0;
}

.aiko-spu-selection .selection-result-spin {
  width: 100%;
  min-height: calc(100vh - 454px);
  display: flex;
  align-items: center;
  justify-content: center;
}
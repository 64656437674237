.as-selection-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 80vh;
  height: 80vh;
}

.as-selection-chart .selectrion-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 80vh;
  height: 80vh;
  cursor: pointer;
  z-index: 1;
}

.as-selection-chart .selectrion-chart canvas {
  cursor: pointer;
}

.as-selection-chart .as-sc-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.as-selection-chart .as-sc-total {
  font-size: 12px;
  color: #999;
  padding-top: calc(100% - 16px);
  text-align: right;
  padding-right: 16px;
}
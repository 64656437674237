.model-classify-main {
  margin-bottom: 24px;
}

.model-classify-main .mcm-title {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}

.model-classify-main .mcm-title::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.login-input-icon {
  color: rgba(0,0,0,0.25)
}

.login-forget-password {
  float: right;
  padding-right: 0;
}

.login-dialog-language {
  display: flex;
  justify-content: right;
  padding-bottom: 9px;
}

.login-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-title::after, .login-title::before {
  width: 112px;
  height: 1px;
  background: rgba(0 , 0, 0, 0.06);
  content: '';
  margin: 0 16px;
}
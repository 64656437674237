.classify-names {
  width: 400px;
  padding: 10px 14px;
  box-sizing: border-box;
  border: 1px dotted #a5a5a5;
}
.classify-names-input {
  border-radius: 4px;
}
.classify-input-width {
  width: 400px!important;
}
.classify-descs {
  max-width: 800px;
  border: 1px dotted #a5a5a5;
}
.classify-descs > h3 {
  padding: 16px 14px;
  margin-bottom: 0;
  font-weight: initial;
  font-size: 14px;
}
.classify-clear {
  padding: 10px 0;
}
.classify-names-input .ant-select-selector {
  border-radius: 4px!important;
}
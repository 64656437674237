@font-face {
  font-family: 'arial';
  src: url('./libs/fonts/arial.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: arial;
}

* {
  font-family: 'arial'!important;
}

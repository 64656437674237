.aiko-sku-attr-layout {
  padding: 0;
}

.aiko-sku-attr-layout .ant-spin-nested-loading>div>.ant-spin {
  min-height: calc(80vh - 64px);
}

.aiko-sku-attr {
  width: 100%;
  min-height: calc(95vh - 64px);
}

.asa-info-card .asa-ic-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.asa-info-card .asa-ic-main .asa-icm-left {
  display: flex;
  align-items: center;
}

.asa-info-card .asa-ic-main .asa-icm-left .asa-icm-list {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.asa-icm-list .asa-icm-l-title {
  margin-right: 8px;
  font-size: 14px;
  color: #333;
  flex-shrink: 0;
}

.asa-icm-list .asa-icm-l-label {
  color: #333;
  display: flex;
  align-items: center;
}

.asa-icm-list .asa-icm-l-label .ant-select {
  width: 200px;
}

.asa-icm-list .asa-icm-l-label .anticon-edit {
  margin-left: 12px;
  cursor: pointer;
}

.asa-tab-card {
  position: relative;
}

.asa-tab-card .asa-tc-button {
  position: absolute;
  top: 6px;
  right: 20px;
}

.asa-tab-card .asa-tab-content-one,
.asa-tab-card .asa-tab-content-two,
.asa-tab-card .asa-tab-content-three {
  padding: 12px 24px;
}

.asa-tab-card .asa-tab-content-one .asa-tc-cc-h1,
.asa-tab-card .asa-tab-content-one .asa-tc-ac-h1,
.asa-tab-card .asa-tab-content-two .asa-tc-cc-h1,
.asa-tab-card .asa-tab-content-two .asa-tc-ac-h1,
.asa-tab-card .asa-tab-content-three .asa-tc-cc-h1,
.asa-tab-card .asa-tab-content-three .asa-tc-ac-h1 {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin-bottom: 12px;
}

.asa-tab-card .ant-tag {
  margin-bottom: 10px;
  margin-right: 12px;
  cursor: pointer;
}

.asa-tab-card .ant-tag:hover {
  opacity: 0.7;
}

.asa-tab-card .ant-tag .anticon-plus,
.asa-tab-card .ant-tag .anticon-close {
  margin-left: 8px;
}

.asa-tab-card .asa-tab-content-two .asa-tc-ac-main .asa-tc-ac-m-list {
  margin-bottom: 12px;
}

.asa-tab-card .asa-tab-content-two .asa-tc-ac-main .asa-tc-ac-m-list .ant-tag {
  margin-bottom: 0;
}

.asa-tab-card .asa-tab-content-two .asa-tc-ac-main .asa-tc-ac-main-add-more-btn {
  margin-bottom: 10px;
}

.asa-spu-select-attr-popover .ant-popover-inner-content {
  overflow-y: scroll;
  height: 60vh;
}

.tab-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkbox-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.checkbox-list {
  width: auto;
  margin-bottom: 10px;
  margin-right: 12px;
}

.tl-header,
.checkbox-list {
  border: 1px solid #DCDFE6;
  padding: 5px 15px 5px 10px;
  border-radius: 3px;
  height: 32px;
  width: auto;
}

.checkbox-list-checked {
  border-color: #1890ff;
}

.checkbox-list-checked .ant-checkbox-wrapper {
  color: #1890ff;
}

.asa-tab-content-one .asa-tc-ac-tab {
  display: flex;
  margin-top: 20px;
}

.asa-tab-content-one .asa-tc-ac-tab .asa-tc-act-valid-common {
  font-size: 14px;
  line-height: 28px;
  height: 30px;
  padding: 0 15px;
  background: #fff;
  border: 1px solid #DCDFE6;
  border-right: none;
  color: #333;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.asa-tab-content-one .asa-tc-ac-tab .asa-tc-act-valid {
  border-radius: 4px 0 0 4px;
}

.asa-tab-content-one .asa-tc-ac-tab .asa-tc-act-invalid {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #DCDFE6;
}

.asa-tab-content-one .asa-tc-ac-tab .asa-tc-act-valid-active {
  font-size: 14px;
  line-height: 28px;
  height: 30px;
  color: #fff;
  padding: 0 15px;
  background-color: #1890ff;
  border-color: #1890ff;
}

.aiko-table-div {
  width: 100%;
  height: 80vh;
  margin-top: 24px;
}

.aiko-table-div .aiko-table {
  width: 100%;
  overflow-x: scroll;
  height: 100%;
  position: relative;
}

.aiko-table-div .aiko-table.aiko-is-windows {
  overflow-y: overlay !important;
  overflow-x: overlay !important;
}

.aiko-table-div .aiko-table.aiko-is-windows::-webkit-scrollbar {
  display: none;
}

.aiko-table-div .aiko-table.aiko-is-windows:hover::-webkit-scrollbar {
  display: block;
}

.aiko-table-div .aiko-table.aiko-is-windows:hover::-webkit-scrollbar-track {
  display: block;
}

.aiko-table-div .aiko-table.aiko-is-windows:hover::-webkit-scrollbar-thumb {
  display: block;
}

.aiko-table-div .aiko-table.aiko-table-x .aikoth-cell-action {
  box-shadow: -6px 0 4px 0 rgba(0, 0, 0, 0.06);
}

.aiko-table-div .aiko-table.aiko-table-x .aikoth-cell-checked {
  box-shadow: 6px 0 4px 0 rgba(0, 0, 0, 0.06);
}

.aiko-table-div .aiko-table.aiko-table-x .aikoth-cell-error-item {
  box-shadow: 6px 0 4px 0 rgba(0, 0, 0, 0.06);
}

.aiko-table-div .aiko-table.aiko-table-x .aikotm-l-cell.aikotm-l-cell-action {
  box-shadow: -6px 0 4px 0 rgba(0, 0, 0, 0.06);
}

.aiko-table-div .aiko-table.aiko-table-x .aikotm-l-cell.aikotm-l-cell-checked {
  box-shadow: 6px 0 4px 0 rgba(0, 0, 0, 0.06);
}

.aiko-table-div .aiko-table.aiko-table-x .aikotm-l-cell.aikotm-l-cell-error-item {
  box-shadow: 6px 0 4px 0 rgba(0, 0, 0, 0.06);
}

.aiko-table-div .aiko-table .aikot-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  z-index: 2;
}

.aiko-table-div .aiko-table .aikot-header .aikoth-cell {
  padding: 16px 4px;
  overflow-wrap: break-word;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  background: #fafafa;
  min-width: 240px;
  border-bottom: 1px solid #e8e8e8;
}

.aiko-table-div .aiko-table .aikot-header .aikoth-cell .ant-select {
  margin-left: 8px;
  font-size: 12px;
  color: #333;
  width: 100px;
}

.aiko-table-div .aiko-table .aikot-header .aikoth-cell-action {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  min-width: 240px;
}

.aiko-table-div .aiko-table .aikot-header .aikoth-cell-action .ant-btn-sm {
  margin-left: 8px;
}

.aiko-table-div .aiko-table .aikot-main {
  width: 100%;
}

.aiko-table-div .aiko-table .aikot-main.aikot-main-empty {
  height: 100%;
  min-width: 100% !important;
}

.aiko-table-div .aiko-table .aikot-main.aikot-empty-main {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.aiko-table-div .aiko-table .aikot-main.aikot-empty-main .ant-empty {
  margin: 0;
}

.aiko-table-div .aiko-table .aikot-main .aikotm-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
}

.aiko-table-div .aiko-table .aikot-main .aikotm-list:hover .aikotm-l-cell {
  background: #e1f1ff;
}

.aiko-table-div .aiko-table .aikot-main .aikotm-list .aikotm-l-cell {
  padding: 16px 4px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-wrap: break-word;
  word-break: break-all;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  position: relative;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  min-width: 240px;
}

.aiko-table-div .aiko-table .aikot-main .aikotm-list .aikotm-l-cell.aikotm-l-cell-action {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  min-width: 240px;
}
#login-layout {
  background-color: #16083f;
}

#login-header {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  position: fixed;
  right: 24px;
}

#login-left-background {
  background-image: image-set(
    url(../images/login_background.png) 1x,
    url(../images/login_background@2x.png) 2x
  );
  background-position: top right;
  background-repeat: no-repeat;
}

#login-right-background {
  background-image: image-set(
    url(../images/login_dialog_background.png) 1x,
    url(../images/login_dialog_background@2x.png) 2x
  );
  background-position: center left;
  background-repeat: no-repeat;
}

#login-dialog {
  /* margin: 25% auto; */
  padding: 24px 0 65px 0;
  width: 95%;
  height: 95%;
  max-width: 512px;
  min-height: 512px;
  max-height: 652px;
}

#login-dialog-form {
  padding-top: 45px;
  max-width: 300px;
  margin: auto
}
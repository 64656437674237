.print-layout {
  display: flex;
  height: 100%;
}
.print-layout p, .print-layout h3 {
  margin: 0;
}
.print-layout .left-pages {
  width: 200px;
  background-color: #fff;
  overflow-y: auto;
  padding-bottom: 24px;
}
.print-layout .right-content {
  position: relative;
  flex: 1;
  overflow-y: auto;
}
.print-layout .pages-minitem {
  margin-top: 24px;
}
.print-layout .pages-minitem .pages-minitem-li {
  width: 89px;
  height: 126px;
  border: 1px solid #CBD5E1;
  border-radius: 6px;
  margin: auto;
  cursor: pointer;
}
.print-layout .pages-minitem .pages-minitem-li.active-li {
  border-color: #1890FF;
}
.print-layout .pages-minitem .pages-minitem-sort {
  text-align: center;
  margin-top: 8px;
}
.print-layout .pages-item {
  display: block;
  width: fit-content;
  margin: auto;
  zoom: 0.8;
}
.print-layout .pages-item .pages-item-number {
  margin: 8px 0;
}
.print-layout .pages-content {
  position: relative;
  width: 1190px;
  height: 1684px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  padding: 40px;
  box-sizing: border-box;
}
.print-layout .paper-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.print-layout .paper-header .header-right {
  text-align: right;
  display: flex;
  height: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.print-layout .paper-header .left-icon {
  width: 140px;
  display: block;
}
.print-layout .paper-header .left-name {
  font-size: 17px;
  font-weight: 600;
  color: #333333;
  margin-left: 8px;
}
.print-layout .paper-header .right-title {
  font-size: 20px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
  position: relative;
  cursor: pointer;
}
.print-layout .paper-header .right-title::before {
  content: "";
  position: absolute;
  left: -10px;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.2s;
}
.print-layout .paper-header .right-title:hover::before {
  border-color: #1890FF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
}
.print-layout .paper-header .right-desc {
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
}
.print-layout .paper-header .right-desc::before {
  content: "";
  position: absolute;
  left: -10px;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.2s;
}
.print-layout .paper-header .right-desc:hover::before {
  border-color: #1890FF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
}
.print-layout .paper-footer {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 12px;
}
.print-layout .paper-footer > div {
  flex: 1;
}
.print-layout .paper-footer > div:nth-child(1) {
  color: rgba(0, 0, 0, 0.5);
}
.print-layout .paper-footer > div:nth-child(2) {
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  text-align: center;
}
.print-layout .paper-footer > div:nth-child(3) {
  color: #0073EA;
  text-align: right;
}
.print-layout .paper-content {
  padding-top: 15px;
  display: flex;
}
.print-layout .paper-content > div {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.export-spin {
  height: 100%;
}
.export-spin .ant-spin-container {
  height: 100%;
}

.page-style-one {
  height: 1504px;
  box-sizing: border-box;
  border: 1px solid transparent;
}
.page-style-one .item-one-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 40px;
}
.page-style-one .item-one-data > div {
  width: 48%;
  border-bottom: 1px dashed transparent;
}
.page-style-one .item-one-data > div > p {
  padding: 4px 8px;
}
.page-style-one .item-one-data .text-desc {
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.9);
  /* font-weight: 200; */
}
.page-style-one .item-one-data .text-value {
  font-size: 24px;
  line-height: 32px;
  /* font-weight: bold; */
  color: rgba(0, 0, 0, 0.9);
}
.page-style-one .item-one-data .text-value-line40 {
  line-height: 40px;
  padding-left: 8px;
}
.page-style-one .data-img {
  display: block;
  max-width: 100%;
  max-height: 700px;
  margin: 80px auto 0;
}

.page-style-two {
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: column; */
  height: 1504px;
  padding-top: 24px;
  padding-bottom: 40px;
  border: 1px solid transparent;
  overflow: hidden;
}
.page-style-two .style-head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.page-style-two .style-head > div {
  width: 48%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px dashed transparent;
}
.page-style-two .style-head > div > p {
  width: 50%;
  padding: 4px 8px;
}
.page-style-two .style-head .text-desc {
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  text-align: left;
  font-weight: 200;
}
.page-style-two .style-head .text-value {
  font-size: 18px;
  line-height: 22px;
  /* font-weight: bold; */
  color: rgba(0, 0, 0, 0.9);
}
.page-style-two .style-content {
  /* height: 1280px; */
  /* margin-top: 50px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* flex: 1 1; */
  /* width: 104%; */
}
.page-style-two .style-content > div {
  /* flex: 0 0 50%; */
  width: 48%;
}
.page-style-two .style-content .text-title {
  font-size: 20px;
  color: #1890FF;
  line-height: 20px;
  font-weight: bold;
  padding: 4px 9px 4px;
  margin-top: 16px;
}
.page-style-two .style-content .style-item-img {
  width: 100%;
  height: 376px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.page-style-two .style-content .style-item {
  display: flex;
  border-bottom: 1px dashed transparent;
}
.page-style-two .style-content .style-item > p {
  width: 50%;
  padding: 4px 8px;
}
.page-style-two .style-content .text-desc {
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  text-align: left;
}
.page-style-two .style-content .text-value {
  font-size: 18px;
  line-height: 22px;
  /* font-weight: bold; */
  color: rgba(0, 0, 0, 0.9);
}

.page-style-three {
  height: 1504px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.page-style-three .style-head {
  display: flex;
  flex-wrap: wrap;
}
.page-style-three .style-head > div {
  width: 50%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}
.page-style-three .style-head > div > p {
  width: 50%;
  padding: 0 10px;
}
.page-style-three .style-head .text-desc {
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  text-align: left;
  font-weight: 200;
}
.page-style-three .style-head .text-value {
  font-size: 18px;
  line-height: 22px;
  /* font-weight: bold; */
  color: rgba(0, 0, 0, 0.9);
}
.page-style-three .style-img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 1080px;
}
.page-style-three .style-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.page-style-three .style-content > div {
  width: 48%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px dashed transparent;
  /* margin-right: 3%; */
}
.page-style-three .style-content > div:nth-child(3n) {
  margin-right: 0;
}
.page-style-three .style-content > div > p {
  width: 50%;
  padding: 10px 8px;
}
.page-style-three .style-content .text-desc {
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  text-align: left;
}
.page-style-three .style-content .text-value {
  font-size: 18px;
  line-height: 22px;
  /* font-weight: bold; */
  color: rgba(0, 0, 0, 0.9);
}
.page-style-three .cover-title {
  display: none;
}

.page-style-four {
  height: 1504px;
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.page-style-four .style-img {
  display: block;
  margin: 0 auto 40px;
  min-width: 600px;
  max-width: 100%;
  max-height: 900px;
}
.page-style-four .style-img2 {
  display: block;
  margin: 40px auto 0;
  min-width: 600px;
  max-width: 100%;
  max-height: 900px;
}
.page-style-four .html-table table {
  width: 100%;
}
.page-style-four .html-table table th, .page-style-four .html-table table td {
  color: rgba(0, 0, 0, 0.7);
  height: 48px;
  border: 1px solid #E5E5E5;
  border-collapse: collapse;
  text-align: center;
  font-size: 18px;
}
.page-style-four .html-table table tbody > tr:nth-child(2n-1) > td {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}
.page-style-four .style-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}
.page-style-four .style-table .table-item {
  width: 5.555%;
  border-right: 1px solid #E5E5E5;
  box-sizing: border-box;
  text-align: center;
}
.page-style-four .style-table .table-item:nth-child(-n+18) {
  border-top: 1px solid #E5E5E5;
}
.page-style-four .style-table .table-item:nth-child(9n+1) {
  border-left: 1px solid #E5E5E5;
}
.page-style-four .style-table .table-item .table-item-th {
  height: 46px;
  font-size: 18px;
  line-height: 46px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid #E5E5E5;
  box-sizing: border-box;
}
.page-style-four .style-table .table-item .table-item-td {
  height: 46px;
  font-size: 18px;
  line-height: 46px;
  color: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  border-bottom: 1px solid #E5E5E5;
}

.page-style-images {
  padding-top: 40px;
}
.page-style-images .style-img {
  display: block;
  margin: 0 auto;
  min-width: 600px;
  max-width: 100%;
  max-height: 900px;
}

.page-style-images .style-img-desc {
  font-size: 22px;
}

.page-border {
  border-color: rgba(24, 144, 255, 0.5);
}

.style-item-border {
  border-color: #D4D4D4 !important;
}

.cursor-item {
  position: relative;
  cursor: pointer;
}
.cursor-item::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px dashed transparent;
  transition: all 0.2s;
}
.cursor-item:hover::before {
  border-color: #1890FF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
}

.ls-item {
  letter-spacing: 3px;
}

/* 预设样式13 */
.right-content-13 .page-style-one .data-img {
  max-height: 450px;
}
.right-content-13 .page-style-three .cover-title {
  display: block;
  font-size: 22px;
}
.right-content-13 .paper-footer > div:nth-child(3) {
  font-size: 18px;
}
.right-content-13 .paper-header .header-right {
  text-align: left;
}
.right-content-13 .pages-content-style-images {
  width: 1684px;
  height: 1190px;
}
.search-item {
  display: flex;
  flex-wrap: wrap;
}

.search-list {
  margin: 4px 8px;
  display: flex;
  align-items: center;
}

.search-list:last-child {
  display: flex;
}

.s-l-title {
  margin-right: 6px;
  font-size: 13px;
}

.s-l-search {
  margin-left: 30px;
}

.s-l-reset {
  margin-left: 18px;
}
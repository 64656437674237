.spu-size-drawer .ant-drawer-body {
  padding: 0
}

.spu-size-drawer .ant-drawer-body .sta-size-info {
  padding: 24px;
  height: 100%;
  width: 100%;
}

.spu-size-drawer .ant-drawer-body .sta-si-list {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.spu-size-drawer .ant-drawer-body .sta-update {
  display: none;
}

.spu-size-drawer .ant-drawer-body .sta-si-list:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.spu-size-drawer .ant-drawer-body .sta-size-info .sta-add-data {
  text-align: center;
}

.spu-size-drawer .ant-drawer-body .sta-size-info .sta-add-data .sta-ad-button {
  width: 100%;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-sil-name {
  margin: 10px 0;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-sil-name .sta-siln-title {
  display: inline-block;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-sil-name .sta-siln-label {
  display: inline-block;
  margin-left: 12px;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-sil-value .sta-silv-label {
  margin-left: 12px;
}

.spu-size-drawer .sta-sil-value {
  margin: 10px 0;
}

.spu-size-drawer .sta-silvl-list {
  padding: 16px 0;
  border-bottom: 1px solid #eee;
}

.spu-size-drawer .sta-silvl-list:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.spu-size-drawer .sta-sil-value .sta-slivl-num {
  display: inline-block;
  margin-top: 12px;
  width: 36px;
  vertical-align: top;
}

.spu-size-drawer .sta-sil-value .sta-slivl-main {
  margin-top: 8px;
  display: inline-block;
  width: calc(100% - 40px);
  vertical-align: top;
}

.spu-size-drawer .sta-slivl-main .sta-silvll-list {
  margin-bottom: 10px;
}

.spu-size-drawer .sta-slivl-main .sta-silvll-title {
  display: inline-block;
}

.spu-size-drawer .sta-slivl-main .sta-silvll-label {
  display: inline-block;
  margin-left: 12px;
}

.spu-size-drawer .sta-ad-button {
  width: 100%;
  margin-bottom: 24px;
}

.spu-size-drawer .ant-drawer-body .sta-si-list {
  position: relative;
}

.spu-size-drawer .ant-drawer-body .sta-sil-delete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  cursor: pointer;
  display: none;
  transition: all 0.15s ease-in-out;
  opacity: 0.7;
  z-index: 10;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-sil-delete:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #333;
  transform: rotate(45deg);
  top: 0;
  left: 0;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-sil-delete:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #333;
  transform: rotate(-45deg);
  top: 0;
  left: 0;
}

.spu-size-drawer .ant-drawer-body .sta-si-list:hover .sta-sil-delete {
  display: block;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-sil-delete:hover {
  opacity: 1;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-silvl-list {
  position: relative;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-silvl-list .sta-silvl-delete {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 18px;
  right: 2px;
  cursor: pointer;
  display: none;
  transition: all 0.15s ease-in-out;
  opacity: 0.7;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-silvl-list .sta-silvl-delete:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #333;
  transform: rotate(45deg);
  top: 0;
  left: 0;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-silvl-list .sta-silvl-delete:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #333;
  transform: rotate(-45deg);
  top: 0;
  left: 0;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-silvl-list:hover .sta-silvl-delete {
  display: block;
}

.spu-size-drawer .ant-drawer-body .sta-si-list .sta-silvl-list .sta-silvl-delete:hover {
  opacity: 1;
}
.aikon-cascader {
  
  /* display: flex; */
  box-shadow: 0 5px 8px #ddd;
  display: inline-block;
}
.aikon-cascader > div {
  overflow-y: auto;
  padding: 8px 0;
  height: 200px;
  display: inline-block;
  background-color: #fff;
}
.aikon-cascader-item {
  position: relative;
  height: 35px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 32px;
  font-size: 14px;
}
.aikon-cascader-item:hover {
  background-color: #f5f5f5;
}
.aikon-cascader-item-arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-size: 12px;
}
.selected-cls {
  color: #1890ff;
  font-weight: 700;
}
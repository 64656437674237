.per-formance-result .chart-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  margin-bottom: 4px;

}

.per-formance-result .chart-label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
}
.category-selector-components .category-selector-image {
  background-image: none;
  height: 140px;
  width: 140px;
  object-fit: contain;
  background: transparent;
}

.category-selector-components .ant-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
}
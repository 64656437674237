.attribute {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.attribute-atom {
  display: inline-block;
  flex: "1 1 auto";
  align-items: center;
  justify-content: center;
  padding: 0px 15px 0px 20px;
  border-radius: 4px;
  font-size: 12px;
  min-width: 60px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
}

.attribute-atom > span {
  vertical-align: middle;
}

.attribute-type-color {
  color: #F5F5F5;
  background-color: #409eff;
}

.attribute-item-color {
  color: #409EFF;
  background-color: #ecf5ff;
}

.close-btn {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 2px 0px;
}

.item-close-btn:hover {
  color: #f5f5f5;
  background-color: #409eff;
}

.type-close-btn:hover {
  color: #f5f5f5;
  background-color: #66b1ff;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.aiko-model-tab-card .amtc-tab-content {
  min-height: calc(100vh - 240px);
  padding: 0 10px;
}

.amtc-tcc-list .amtc-tccl-tag {
  display: inline-block;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0px 15px 0px 20px;
  margin: 4px 0;
  border-radius: 4px;
  font-size: 12px;
  min-width: 60px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  color: #F5F5F5;
  background-color: #409eff;
}

.amtc-tcc-list .amtc-tccl-tag .anticon-close {
  margin-left: 5px;
}

.amtc-tcc-list .amtc-tccl-tag .anticon-close:hover {
  color: #fff;
}

.amtc-tab-content .amtc-add-cus-spec-btn {
  margin-top: 10px;
}

.amtc-tcs-list .amtc-tcsl-tag {
  display: inline-block;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0px 15px 0px 20px;
  margin: 4px 0;
  border-radius: 4px;
  font-size: 12px;
  min-width: 60px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  color: #F5F5F5;
  background-color: #409eff;
}

.amtc-tcs-list .amtc-tcsl-tag .anticon-close {
  margin-left: 5px;
}

.amtc-tcs-list .amtc-tcsl-tag .anticon-close:hover {
  color: #fff;
}

.amtc-tab-content .amtc-add-spec-btn {
  margin-top: 10px;
}

.amtc-tcs-list .amtc-tcs-list-driver {
  color: #333;
  display: inline-block;
  margin: 0 10px;
}

.amtc-tcs-list .amtc-tcsl-more-tag {
  display: inline-block;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0px 15px 0px 20px;
  margin: 4px 10px 4px 0;
  border-radius: 4px;
  font-size: 12px;
  min-width: 60px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  color: #409EFF;
  background-color: #ecf5ff;
  border-color: #ecf5ff;
}

.amtc-tcs-list .amtc-tcsl-more-tag .anticon-close {
  margin-left: 5px;
}
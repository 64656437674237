.spu-attr-drawer .ant-drawer-body {
  padding: 0
}

.spu-attr-drawer .ant-drawer-body .sta-attr-info {
  padding: 24px;
  height: 100%;
  min-width: 100%;
}

.spu-attr-drawer .ant-drawer-body .sta-attr-info .sta-add-data {
  text-align: center;
}

.spu-attr-drawer .ant-drawer-body .sta-attr-info .sta-add-data .sta-ad-button {
  width: 100%;
  margin-bottom: 24px;
}

.spu-attr-drawer .ant-drawer-body .sta-ai-list:last-child {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.spu-attr-drawer .ant-drawer-body .sta-ai-list .sta-ali-list {
  margin: 10px 0;
}

.spu-attr-drawer .sta-attr .sta-ai-list {
  position: relative;
}

.spu-attr-drawer .sta-attr .sta-ai-list .sta-ail-delete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  cursor: pointer;
  display: none;
  transition: all 0.15s ease-in-out;
  opacity: 0.7;
}

.spu-attr-drawer .sta-attr .sta-ai-list .sta-ail-delete:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #333;
  transform: rotate(45deg);
  top: 0;
  left: 0;
}

.spu-attr-drawer .sta-attr .sta-ai-list .sta-ail-delete:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #333;
  transform: rotate(-45deg);
  top: 0;
  left: 0;
}

.spu-attr-drawer .sta-attr .sta-ai-list:hover .sta-ail-delete {
  display: block;
}

.spu-attr-drawer .sta-attr .sta-ai-list .sta-ail-delete:hover {
  opacity: 1;
}
.spu-list-page-header-extra-export {
  margin-right: 10px;
}

.spu-names {
  width: 400px;
  padding: 10px 14px;
  box-sizing: border-box;
  border: 1px dotted #a5a5a5;
}
.spu-names-input {
  border-radius: 4px;
}
.spu-input-width {
  width: 400px!important;
}
.spu-descs {
  max-width: 800px;
  border: 1px dotted #a5a5a5;
}
.spu-descs > h3 {
  padding: 16px 14px;
  margin-bottom: 0;
  font-weight: initial;
  font-size: 14px;
}
.spu-clear {
  padding: 10px 0;
}
.spu-names-input .ant-select-selector {
  border-radius: 4px!important;
}
.edit-spu-info .ant-dropdown-trigger {
  border-radius: 4px!important;
}